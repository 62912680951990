import { GetServerSideProps, NextPage } from 'next';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getMetaInformation, MetaInformation } from 'src/utils/metaInformation';
import { defaultLocale, Locale } from '../locales';
import { doAuthUser } from '../redux/modules/auth/actions';
import { AppState } from '../redux/reducers';
import JoinLogin from 'src/components/JoinLogin';
import StandardHead from 'src/components/StandardHead';

export type LoginNextProps = {
  pathname: string;
  locale: Locale;
  meta: MetaInformation;
  ccgid?: string;
  resume?: boolean;
  redirectUrl?: string;
};

export type LoginProps = LoginReducerProps & LoginNextProps;

export const PageLogin: NextPage<LoginProps, LoginNextProps> = ({
  pathname,
  syncing,
  doAuthUser,
  meta,
  ccgid,
  resume,
  redirectUrl,
  redirecting
}) => {
  useEffect(() => {
    doAuthUser(pathname, ccgid, resume);
  }, []);

  const { title, description, url, image } = meta.og;

  return (
    <JoinLogin redirecting={redirecting} syncing={syncing} ccgid={ccgid} redirectUrl={redirectUrl}>
      <StandardHead
        {...{
          title,
          customTabTitleKey: 'login.pageTabTitle',
          description,
          url,
          image,
          twitter_card: meta.twitter.card
        }}
      />
    </JoinLogin>
  );
};

export const getServerSideProps: GetServerSideProps<LoginNextProps> = async ctx => {
  const { query, locale = defaultLocale() } = ctx;
  const pathname = query.redirectUrl?.toString() || `/${locale}/cockpit`;
  const redirectUrl = query.redirectUrl?.toString() || '';
  const ccgid = query.ccgid?.toString() || '';
  const resume = typeof query.resume !== 'undefined';

  const meta = await getMetaInformation(pathname, locale as Locale);
  return { props: { pathname, meta, locale: locale as Locale, ccgid, resume, redirectUrl } };
};

export const mapStateToPropsLogin = ({ userReducer }: AppState) => ({
  syncing: userReducer.syncing,
  redirecting: userReducer.redirecting
});
export const mapDispatchToPropsLogin = (dispatch: Dispatch) => bindActionCreators({ doAuthUser }, dispatch);

type LoginReducerProps = ReturnType<typeof mapStateToPropsLogin> & ReturnType<typeof mapDispatchToPropsLogin>;

export default connect(mapStateToPropsLogin, mapDispatchToPropsLogin)(PageLogin);
